import {
  Autocomplete, AutocompleteProps, LegacyCard, FormLayout, Select,
  LegacyStack, TextField, Text, Thumbnail, Button
} from "@shopify/polaris"
import { VFC, useEffect, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
  StylingModelGender
} from "../../../../api-types"
import { AccountType } from "../types"
import {
  useStylingModels,
  useStylingModelsFilters
} from "../../../../entrypoints/admin/styling_models/hooks"
import { OptionDescriptor } from "@shopify/polaris/build/ts/latest/src/types"
import { compact, set } from "lodash"
import { DeleteIcon, IconsIcon } from "@shopify/polaris-icons";

export type StylingFormModelInformationFields = {
  modelGender?: StylingModelGender,
  modelHeight: string
  modelId?: string
}

const modelGenderOptions = Object.values(StylingModelGender).map((val: typeof StylingModelGender[keyof typeof StylingModelGender]) => (
  {
    label: val,
    value: val
  }
))

type StylingFormModelInformationCardProps = {
  accountType: AccountType
  stylingModel?: SelectedModel
}

export type SelectedModel = {
  id: string
  name: string
  profileImageUrl: string
  gender: StylingModelGender
  height?: number
}

export const StylingFormModelInformationCard: VFC<StylingFormModelInformationCardProps> = (
  { accountType, stylingModel }
) => {
  const { t } = useTranslation()
  const {
    control,
    watch,
    setValue
  } = useFormContext<StylingFormModelInformationFields>()
  const modelId = watch('modelId')
  const [
    selectedModel,
    setSelectedModel
  ] = useState<SelectedModel | undefined>(stylingModel)

  useEffect(() => {
    setValue('modelId', selectedModel?.id)
    setValue('modelGender', selectedModel?.gender)
    setValue('modelHeight', `${selectedModel?.height}`)
  }, [selectedModel])

  const {
    filters,
    appliedFilters,
    queryValue,
    setQueryValue,
    handleQueryValueRemove,
    handleFiltersClearAll,
    variables,
    setPreviousPage,
    setNextPage,
  } = useStylingModelsFilters(100)

  const stylingModelsResult = useStylingModels(variables)
  const stylingModels = stylingModelsResult.data?.stylingModels.edges.map(e => e.node) || []
  const stylingModelOptions: OptionDescriptor[] = stylingModels.map(model => {
    return {
      value: model.id,
      label: (
        <LegacyStack vertical={false} spacing={'loose'}>
          <Thumbnail size={'small'} source={model.profileImageUrl} alt={''} />
          <LegacyStack vertical spacing={'extraTight'}>
            <Text variant="bodyMd" as="span" fontWeight="semibold">{model.name}</Text>
            <Text variant="bodyMd" as="span" tone="subdued">
              {compact([model.gender, model.height ? `${model.height}cm` : void 0]).join(' / ')}
            </Text>
          </LegacyStack>
        </LegacyStack>
      )
    };
  })
  const onSelect: AutocompleteProps['onSelect'] = (values: string[]) => {
    const selected = values.at(0)
    if (selected) {
      const model = stylingModels.find(m => m.id === selected)
      model && setSelectedModel({
        ...selectedModel,
        id: model?.id ?? '',
        name: model?.name ?? '',
        profileImageUrl: model?.profileImageUrl ?? '',
        gender: model?.gender!,
        height: model?.height ?? void 0
      })
    }
  }

  return (
    <LegacyCard sectioned title={t("Model Info")}>
      <FormLayout>
        {
          accountType === "staff" && (
            <FormLayout>
              <FormLayout.Group>
                <Autocomplete
                  options={stylingModelOptions}
                  selected={modelId ? [modelId] : []}
                  onSelect={onSelect}
                  textField={
                    <Autocomplete.TextField
                      label={void 0}
                      labelHidden
                      placeholder="Search Models"
                      autoComplete="off"
                      value={queryValue}
                      onChange={setQueryValue}
                    />
                  }
                />
              </FormLayout.Group>
              {
                selectedModel && (
                  <FormLayout>
                    <FormLayout.Group>
                      <LegacyStack
                        vertical={false}
                        spacing={'loose'}
                        distribution={'leading'}
                        alignment={'center'}>
                        <Thumbnail
                          size={'small'}
                          source={selectedModel?.profileImageUrl ?? ''}
                          alt={''}
                        />
                        <LegacyStack vertical spacing={'extraTight'} distribution={'fill'}>
                          <Text variant="bodyMd" as="span" fontWeight="semibold">{selectedModel?.name}</Text>
                          <Text variant="bodyMd" as="span" tone="subdued">
                            {
                              compact([
                                selectedModel?.gender,
                                selectedModel?.height ? `${selectedModel?.height}cm` : void 0
                              ]).join(' / ')
                            }
                          </Text>
                        </LegacyStack>
                        <LegacyStack.Item fill />
                        <Button
                          size={'slim'}
                          icon={DeleteIcon}
                          onClick={() => {
                            setSelectedModel(void 0)
                          }}
                        />
                      </LegacyStack>
                    </FormLayout.Group>
                  </FormLayout>
                )
              }
              <hr />
            </FormLayout>
          )
        }
        <FormLayout.Group>
          <Controller
            control={control}
            name="modelGender"
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Select
                  label={t("Gender")}
                  options={modelGenderOptions}
                  placeholder={t("Please Select")}
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )
            }}
          />
          <Controller
            control={control}
            name="modelHeight"
            rules={{
              required: { value: true, message: t("Required") },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <TextField
                  requiredIndicator
                  label={`${t("Height")}(cm)`}
                  type="number"
                  value={value || ""}
                  onChange={(heightString) => onChange(parseInt(heightString) || "")}
                  autoComplete="off"
                  error={error?.message}
                />
              )
            }}
          />
        </FormLayout.Group>
      </FormLayout>
    </LegacyCard>
  );
}
