import * as yup from 'yup';

export const stylingFormValidationSchema = yup.object().shape(
  {
    modelGender: yup.string(),
    modelHeight: yup.lazy((value) =>
      value === ''
        ? yup.string().required()
        : yup.number()
            .min(1, 'must be a positive number over zero')
            .required('model height is required')
    ),
    images: yup.array().of(
      yup.object().shape(
        {
          id: yup.string().required(),
          displayUrl: yup.string(),
          uploadUrl: yup.string().required(),
          loading: yup.boolean(),
          error: yup.string()
        }
      )
    ).min(1, 'Styling media must have at least 1 item').required(),
    productVariants: yup.array().of(
      yup.object().shape({
        id: yup.string().required(),
        productTitle: yup.string().required(),
        title: yup.string().required(),
        sku: yup.string().required(),
        productImage: yup.string().required(),
        vendor: yup.string().required(),
        shop: yup.object().shape(
          {
            id: yup.string(),
            shopName: yup.string()
          }
        )
      }).required()
    ).min(1, 'must to be linked with at least 1 product variant').required()
  }
).required()
